<template>
  <div class="h-full w-full bg-white flex flex-col items-center justify-center py-10 px-20">
    <div class="border-2 border-red-600 p-12 flex flex-row w-full  rounded-lg">
      <div class="flex flex-col items-center justify-center text-red-600 text-5xl">
        <i class="fal fa-exclamation-triangle"></i>
      </div>
      <div class="flex flex-col justify-center items-center w-full text-red-700">
        <div class="text-2xl">VIN Not found</div>
        <div class="text-4xl my-10 font-medium">{{$store.getters["tradein/vin"]}}</div>
        <div class="flex flex-row content-around">
                <span class="inline-flex rounded shadow-sm">
                    <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded text-red-700 bg-red-100 hover:bg-red-50 focus:outline-none focus:border-red-300 focus:shadow-outline-red active:bg-red-200 transition ease-in-out duration-150" v-on:click="retry">
                        <i class="fas fa-undo"></i>
                        <span class="ml-4">retry</span>
                    </button>
                </span>
          <span class="inline-flex rounded shadow-sm ml-4">
                    <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition ease-in-out duration-150" v-on:click="manual">
                        <i class="fas fa-check"></i>
                        <span class="ml-4">Configure manually</span>
                    </button>
                </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
  methods: {
    retry: function () {
      this.$store.commit("tradein/setPart", "start");
    },
    manual: function () {
      this.$store.commit("tradein/setPart", "select-make");
    }
  }
}
</script>

<style scoped>

</style>
